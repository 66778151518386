import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Settings

const ShopSettings = Loader(
  lazy(() => import('src/content/settings'))
);

const settingsRoutes = [
  {
    path: '/',
    element: <Navigate to="shop" replace />
  },
  {
    path: 'shop',
    element: <ShopSettings />
  }
];

export default settingsRoutes;
