import { createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import axiosMock from 'src/utils/axios';
// import { isAuthenticated, JWT_SECRET, COOKIE_SECRET } from 'src/utils/jwt';
import PropTypes from 'prop-types';
import ls from 'localstorage-slim';
import { EncryptStorage } from 'encrypt-storage';
import { useNavigate } from "react-router-dom";

// require('dotenv').config();


// export const encryptStorage = new EncryptStorage(COOKIE_SECRET, {
export const encryptStorage = new EncryptStorage(process.env.REACT_APP_COOKIE_SECRET, {
    storageType: 'sessionStorage'
});

const dotenv = require('dotenv');

dotenv.config({ path: '.env' });

ls.config.encrypt = true; // set up encryption/obfuscation globally
// ls.config.secret = COOKIE_SECRET; // set Decryption Key
ls.config.secret = process.env.REACT_APP_COOKIE_SECRET; // set Decryption Key

const initialAuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
};

const setSession = (accessToken) => {
    if (accessToken) {
        // localStorage.setItem('accessToken', accessToken);
        encryptStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        axiosMock.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        // localStorage.removeItem('accessToken');
        encryptStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
        delete axiosMock.defaults.headers.common.Authorization;
    }
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user } = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user
        };
    },
    LOGIN: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null
    }),
    REGISTER: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    }
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
    ...initialAuthState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    // let shops = localStorage.getItem('shops') || [];
    let shops = [];
    shops = shops && typeof shops === 'string' ? JSON.parse(shops) : shops;
console.log('shops',)
    let activeShop = localStorage.getItem('activeShop');
    activeShop = activeShop && typeof activeShop === 'string' ? JSON.parse(activeShop) : activeShop;

    const navigate = useNavigate();

    useEffect(() => {
        console.log('fire on page load ', activeShop, 's: ', shops);
        // check for active shop
        if(!activeShop || typeof activeShop !== 'object' || !activeShop.id){
            if(shops && Array.isArray(shops)){

                if(shops.length === 1){
                    localStorage.setItem('activeShop', JSON.stringify(shops[0]));
                    navigate('/extended-sidebar/reports');
                }else if(shops.length > 1){
                    navigate('/extended-sidebar/shop/active');
                }else{
                    navigate('/extended-sidebar/');
                }
            }else{ 
                console.log('11');
                navigate('/extended-sidebar/');
            }
        }

        const initialize = async () => {
            try {
                // const accessToken = window.localStorage.getItem('accessToken');
                const accessToken = encryptStorage.getItem('accessToken');
                // if (accessToken && isAuthenticated(accessToken, JWT_SECRET)) {
                if (accessToken) {
                    setSession(accessToken);

                    const response = await axiosMock.get(
                        '/api/account/personal'
                    );
                    const { user } = response.data;
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            user: null
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        };

        initialize();
    }, []);

    const login = async (username, password) => {
        try{
            const res = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
                {
                    username,
                    password
                }
            );

            if(res.status === +process.env.REACT_APP_RECORD_NOT_FOUND){ // no prod
                // eslint-disable-next-line
                alert('Incorrect username and/or password. Please double check and try again or use the forgot password link')
            }else{
                const { error, message } = res.data;
    
                if(!error){
                    const { accessToken, user } = res.data.data;
                    encryptStorage.setItem('user', user);
                    localStorage.setItem('user', JSON.stringify(user));
                
                    if(user.shops && Array.isArray(user.shops) && user.shops.length > 0){
                        const {shops} = user;
                        localStorage.setItem('shops', JSON.stringify([shops[0]]));
                        localStorage.setItem('activeShop', JSON.stringify(user.shops[0]));
                        if(shops.length === 1){
                            localStorage.setItem('activeShop', JSON.stringify(shops[0]));
                        }
                        
                        await loadReportDetails(accessToken);                                     
                    }
            
                    setSession(accessToken);
                    dispatch({
                        type: 'LOGIN',
                        payload: {
                            user
                        }
                    });
                }else{
                    // eslint-disable-next-line
                    alert(message);
                }
            }
    
        }catch(err){
            const message = err?.response?.data?.message || err.message;
            console.error(err);
            // eslint-disable-next-line
            alert(message);
        }
    };

    const logout = async () => {
        setSession(null);
        dispatch({ type: 'LOGOUT' });
    };

    const register = async (email, name, password) => {
        const response = await axiosMock.post('/api/account/register', {
            email,
            name,
            password
        });
        const { accessToken, user } = response.data;

        window.localStorage.setItem('accessToken', accessToken);
        dispatch({
            type: 'REGISTER',
            payload: {
                user
            }
        });
    };

    const loadReportDetails = async (token) => {
        await loadOrders(token);
    }

    const loadOrders = async (token) => {
        try{
            let activeShop = localStorage.getItem('activeShop');
            activeShop = JSON.parse(activeShop);

            const res = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/order?shopSlug=${activeShop.slug}&page=1&size=1000000`,
                {
                    headers: {
                      'Authorization': `Bearer ${token}` 
                    }
                }
            );

            const { error, data } = res.data;

            if(!error && Array.isArray(data)){
                localStorage.setItem('orders', JSON.stringify(data));
            }else{
                localStorage.setItem('orders', JSON.stringify([]));                
            }            
        }catch(err){
            // const message = err?.response?.data?.message || err.message;
            console.error(err);
            localStorage.setItem('orders', JSON.stringify([])); 
        }
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default AuthContext;