import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import BoxedSidebarLayout from 'src/layouts/BoxedSidebarLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';
import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';
import BottomNavigationLayout from 'src/layouts/BottomNavigationLayout';
import TopNavigationLayout from 'src/layouts/TopNavigationLayout';

import dashboardsRoutes from './dashboards';
import blocksRoutes from './blocks';
import applicationsRoutes from './applications';
import managementRoutes from './management';
import documentationRoutes from './documentation';
import accountRoutes from './account';
import baseRoutes from './base';

import shopRoutes from './shop';
import reportRoutes from './reports';
import productRoutes from './product';
import bankAccountRoures from './bankAccount';
import orderRoute from './order';
import paymentRoute from './payment';
import profileRoute from './profileRoute';
import complianceRoutes from './compliance';
import settingsRoutes from './settings';
import sliderRoutes from './slider';
// import emailRoute from './email';

import TestRoute from './test';

const router = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: baseRoutes
  },

  // Extended Sidebar Layout

  {
    // path: 'extended-sidebar',
    path: 'extended-sidebar',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'reports',
        children: reportRoutes
      },
      {
        path: 'shop',
        children: shopRoutes
      },
      {
        path: 'product',
        children: productRoutes
      },
      {
        path: 'bank-account',
        children: bankAccountRoures
      },
      {
        path: 'order',
        children: orderRoute
      },
      {
        path: 'payment',
        children: paymentRoute
      },
      {
        path: 'profile',
        children: profileRoute
      },
      // {
      //   path: 'email',
      //   children: emailRoute
      // },
      
      {
        path: 'test',
        children: TestRoute
      },      
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      // {
      //   path: 'blocks',
      //   children: blocksRoutes
      // },
      // {
      //   path: 'applications',
      //   children: applicationsRoutes
      // },
      {
        path: 'management',
        children: managementRoutes
      },
      {
        path: 'compliance',
        children: complianceRoutes
      },
      {
        path: 'settings',
        children: settingsRoutes
      },
      {
        path: 'sliders',
        children: sliderRoutes
      },
    ]
  },

];

if(process.env.NODE_ENV === 'development'){
  router.push(
    // Documentation
    {
      path: 'docs',
      element: <DocsLayout />,
      children: documentationRoutes
    },

      // Boxed Sidebar Layout

  {
    path: 'boxed-sidebar',
    element: (
      <Authenticated>
        <BoxedSidebarLayout />
      </Authenticated>
    ),
    children: [
            // My Routes
            {
              path: 'reports',
              children: reportRoutes
            },
            {
              path: 'shop',
              children: shopRoutes
            },
            {
              path: 'product',
              children: productRoutes
            },
            {
              path: 'bank-account',
              children: bankAccountRoures
            },
      {
        path: '/',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  },

  // Accent Sidebar Layout

  {
    path: 'accent-sidebar',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
            // My Routes
            {
              path: 'reports',
              children: reportRoutes
            },
            {
              path: 'shop',
              children: shopRoutes
            },
            {
              path: 'product',
              children: productRoutes
            },
            {
              path: 'bank-account',
              children: bankAccountRoures
            },

            



      {
        path: '/',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  },

  // Collapsed Sidebar Layout

  {
    path: 'collapsed-sidebar',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: [
            // My Routes
            {
              path: 'reports',
              children: reportRoutes
            },
            {
              path: 'shop',
              children: shopRoutes
            },
            {
              path: 'product',
              children: productRoutes
            },
            {
              path: 'bank-account',
              children: bankAccountRoures
            },

      {
        path: '/',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  },

  // Bottom Navigation Layout

  {
    path: 'bottom-navigation',
    element: (
      <Authenticated>
        <BottomNavigationLayout />
      </Authenticated>
    ),
    children: [
      // My Routes
      {
        path: 'reports',
        children: reportRoutes
      },
      {
        path: 'shop',
        children: shopRoutes
      },
      {
        path: 'product',
        children: productRoutes
      },
      {
        path: 'bank-account',
        children: bankAccountRoures
      },

      {
        path: '/',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  },

  // Top Navigation Layout

  {
    path: 'top-navigation',
    element: (
      <Authenticated>
        <TopNavigationLayout />
      </Authenticated>
    ),
    children: [

      // My Routes
      {
        path: 'reports',
        children: reportRoutes
      },
      {
        path: 'shop',
        children: shopRoutes
      },
      {
        path: 'product',
        children: productRoutes
      },
      {
        path: 'bank-account',
        children: bankAccountRoures
      },


      {
        path: '/',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  },

  // Accent Header Layout

  {
    path: 'accent-header',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: [
      // My Routes
      {
        path: 'reports',
        children: reportRoutes
      },
      {
        path: 'shop',
        children: shopRoutes
      },
      {
        path: 'product',
        children: productRoutes
      },
      {
        path: 'bank-account',
        children: bankAccountRoures
      },


      {
        path: '/',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  },

  )
}

export default router;
